<template>
    <div class="px-3 py-2">
        <v-form ref="addexp">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="add_expense" backdrop style="direction:ltr" right title="اضافة مصروف دوري" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span style="font-size:.8em;">{{$parent.lang.add_expenses}}</span>
        </div>
        <div @click="hide" id="hideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12">
                        <label>{{$parent.lang.expense_type}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.expense_type + '*'"
                            v-model="expens_type"
                            >{{ expens_type }}</b-form-input>
                    </v-col>
                    <v-col cols="12">
                        <label>{{$parent.lang.expense_type}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.expense_total + '*'"
                            v-model="ftotal"
                            :rules="numrules"
                            >{{ ftotal }}</b-form-input>
                    </v-col>
                    <v-col cols="12">
                        <label>{{$parent.lang.expense_period}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                            v-model="period"
                            :options="periods"
                            :label="$parent.lang.expense_period"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" style="font-size:0.7rem;color:red;">
                        * {{$parent.lang.calculation_will_be_done_automatic}}
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addExpense()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
           numrules:[
               v => !!v || this.$parent.require_filed
           ],
           ftotal:0, 
           
           period:1,
           expens_type: this.$parent.lang.office_rent
        }
    },
    computed: {
        periods: function(){
            return [
               {text: this.$parent.lang.yearly , value: 1},
               {text: this.$parent.lang.midterm , value: 2},
               {text: this.$parent.lang.quarterly , value: 3},
               {text: this.$parent.lang.monthly , value: 4},
               {text: this.$parent.lang.daily , value: 5},
           ]
        }
    },
    methods: {
        addExpense(){
            if(!this.$refs.addexp.validate()){
                return false;
            }
            const post = new FormData();
            post.append("type" , "addExpense");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[period]",this.period);
            post.append("data[ftotal]",this.ftotal);
            post.append("data[expens_type]",this.expens_type);
            
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    //// console.log(this.$parent);
                    this.$parent.getExpenses();
                    this.ftotal = 0
                    document.getElementById('hideExp').click();
                }
            );
        }
    },
}
</script>